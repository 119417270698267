<template>
	<div class="page">
		<van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('机器人详情')" left-arrow @click-left="onClickLeft" >
		</van-nav-bar>
		<div class="page-card">
			<div class="page-card_bd">
				<div class="robot-detail">
					<div class="robot-logo">
						<img src="../../assets/pictures/ro-logo@2x.png" style="background-color: #4f62c0" >
					</div>
					<div class="robot-title">{{$t(robot.name)}}</div>
					<div class="robot-meta">
						<div class="col">
							<div class="text">{{$t('预计日收益')}}</div>
							<div class="value">{{parseFloat(robot.profit).toFixed(2)}} {{$t('USDT')}}</div>
						</div>
						<div class="col">
							<div class="text">{{$t('有效期')}}</div>
							<div class="value">{{robot.days}} {{$t('天')}}</div>
						</div>
						<div class="col">
							<div class="text">{{$t('交易价格')}}</div>
							<div class="value text-red">
								{{parseInt(robot.min_money)}} {{$t('USDT')}}
								<span class="sm" v-if="robot.level == 0">{{$t('免费')}}</span>
							</div>
						</div>
					</div>
					<div class="robot-js">
						<van-stepper v-model="num" :disabled="robot.level == 0 ? 'disabled' : false "/>
						<div class="js-value">{{parseFloat(num * robot.price).toFixed(2)}} {{$t('USDT')}}</div>
					</div>
					<div class="robot-less"><span>{{$t('当前余额')}}：</span>{{user.balance}} {{$t('USDT')}}</div>
					<div style="padding: 20px; font-size: 12px;color: #cccccc">
						<div class="text" v-html="$t(robot.desc)"></div>
					</div>

					<div class="ro-footer">
						<van-button color="#154dc6" @click="buyRobot">{{$t('创建')}}</van-button>

					</div>
				</div>
			</div>
		</div>
		<Foot :active="0"/>
	</div>
</template>

<script>
	import Foot from "@/components/Foot.vue";

	export default {
		components: {Foot},
		data() {
			return {
				rid : '',
				num:'1',
				robot: {},
				user: {},
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			getData() {
				this.$axios
						.get('/robot/list?id=' + this.rid)
						.then((res) => {
							if (res.data && res.data.code == 0) {
								this.loading = false;
								this.robot = res.data.data[0];
							}
						}).catch((err) => {
					console.log(err.message);
					// this.$toast.fail(err.message);
				});
			},
			buyRobot() {
				this.$axios
						.post('/robot/buy_robot', {id: this.robot.id, num: this.num})
						.then((res) => {
							let result = res.data;
							if (result.code === 0) {
								this.refUser();
								this.$toast.success(result.msg);
							} else {
								this.$toast.fail(result.msg);
							}
						});
			},
			refUser(){
				this.getUser().then((res) => {
					this.user = res.data;
				});
			}
		},
		created() {
			if (this.$route.query.id == undefined) {
				this.$router.push({path: '/robot-list'})
			}
			this.rid = this.$route.query.id;
			this.getData();
			this.refUser();
		}
	}
</script>
<style scoped="scoped">
	.page{
		min-height: 100vh;
		/*background:url(../../assets/pics/Background.png) no-repeat;*/
		/*background-size: 100% auto;*/
		background-color: #FFFFFF;
	}
</style>
